import allRules from "../../../../plugins/form-rules";
export default [
  {
    text: "Image",
    value: "name",
    width: "80px",
    order: 1,
    sortable: false,
    hidable: false,
    hidden: false,
  },
  {
    text: "Category",
    value: "category",
    sortable: false,
    width: "250px",
    cellClass: "cellHeight",
    order: 2,
    hidable: false,
    hidden: false,
    editable: false,
  },
  {
    text: "Tag",
    value: "tag",
    order: 3,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
    type: "textfield",
    serverKey: "Tag",
    validation: () => [allRules.required, allRules.lengthMax(12)],
  },
  {
    text: "Provider",
    value: "provider",
    order: 4,
    hidable: true,
    hidden: false,
    editable: false,
    width: "100px",
    cellClass: "cellHeight",
  },
  {
    text: "Installer",
    value: "installer",
    order: 5,
    hidable: true,
    hidden: false,
    editable: false,
    width: "100px",
    cellClass: "cellHeight",
  },
  {
    text: "Description",
    value: "description",
    order: 6,
    hidable: true,
    hidden: false,
    sortable: false,
    editable: false,
    width: "250px",
    cellClass: "cellHeight",
  },
  {
    text: "Make",
    value: "make",
    order: 7,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
    type: "textfield",
    serverKey: "Make",
    validation: () => [allRules.required],
  },
  {
    text: "Model",
    value: "model",
    order: 8,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
    type: "textfield",
    serverKey: "Model",
    validation: () => [allRules.required],
  },
  {
    text: "Part Number",
    value: "clientPartNumber",
    order: 9,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
    type: "textfield",
    validation: () => [],
  },
  {
    text: "MSRP",
    value: "msrp",
    order: 10,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
    type: "textfield",
    validation: () => [allRules.requiredNumber],
  },
  {
    text: "Availability",
    value: "availability",
    order: 11,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
  },
  {
    text: "Street Price",
    value: "streetPrice",
    order: 12,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
    type: "textfield",
    validation: () => [allRules.requiredNumber],
  },
  {
    text: "Dealer Price",
    value: "dealerPrice",
    order: 13,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
    type: "textfield",
    validation: () => [allRules.requiredNumber],
  },
  {
    text: "Weight (Lbs.)",
    value: "weight",
    order: 14,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
    type: "textfield",
    validation: () => [allRules.requiredNumber],
  },
  {
    text: "Is Rack Mountable?",
    value: "isRackMountable",
    sortable: false,
    order: 15,
    hidable: true,
    hidden: false,
    editable: false,
    width: "165px",
    cellClass: "cellHeight",
  },
  {
    text: "Rack Units (RU)",
    value: "rackUnits",
    order: 16,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
  },
  {
    text: "Power Consumption (Watts)",
    value: "powerConsumption",
    sortable: false,
    order: 17,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
  },
  {
    text: "HeatLoad & HVAC Calculation",
    value: "powerAutoCalculate",
    sortable: false,
    order: 18,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
  },
  {
    text: "HeatLoad (BTU/Hr)",
    value: "heatload",
    order: 19,
    hidable: true,
    hidden: false,
    editable: false,
    sortable: false,
    width: "160px",
    cellClass: "cellHeight",
    type: "textfield",
    validation: () => [allRules.requiredNumber],
  },
  {
    text: "HVAC Tonnage",
    value: "hvacTonnage",
    sortable: false,
    order: 20,
    hidable: true,
    hidden: false,
    editable: false,
    width: "150px",
    cellClass: "cellHeight",
    type: "textfield",
    validation: () => [allRules.requiredNumber],
  },
  {
    text: "Url",
    value: "url",
    order: 21,
    hidable: true,
    hidden: false,
    editable: false,
    sortable: false,
    width: "150px",
    cellClass: "cellHeight",
  },
  {
    text: "Hashtags",
    value: "equipmentHashtags",
    order: 22,
    hidable: true,
    hidden: false,
    editable: false,
    sortable: false,
    cellClass: "cellHeight",
  },
  {
    text: "Created Date",
    value: "createDate",
    width: "130px",
    order: 23,
    hidable: true,
    hidden: false,
    editable: true,
    sortable: true,
  },
  {
    text: "Last Update",
    value: "updateDate",
    width: "130px",
    order: 24,
    hidable: true,
    hidden: false,
    editable: true,
    sortable: true,
  },
  {
    text: "Actions",
    value: "actions",
    width: "96px",
    align: "left",
    sortable: false,
    order: 25,
    hidable: true,
    hidden: false,
    cellClass: "stickyCell",
    class: "stickyCell",
  },
];
